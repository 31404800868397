<template>
  <VaSidebar
    :width="props.width"
    :minimized="props.minimized"
    :minimized-width="props.minimizedWidth"
    :animated="props.animated"
    style="background-color: #1C1C1C"
  >
    <MenuMinimized v-if="minimized" :items="filteredItems" />
    <MenuAccordion v-else :items="filteredItems" />
  </VaSidebar>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { useUserStore } from '@/stores/user-store'
import NavigationRoutes from './NavigationRoutes'
import MenuAccordion from './menu/MenuAccordion.vue'
import MenuMinimized from './menu/MenuMinimized.vue'

interface SidebarProps {
  width: string
  minimized: boolean
  minimizedWidth: string
  animated: boolean
}

const props = defineProps<SidebarProps>()
const userStore = useUserStore(); 
const items = ref(NavigationRoutes.routes)


const filteredItems = ref(items.value.filter(item => {
  if (item.requiresAdmin && !userStore.isAdmin) {
    return false; 
  }
  return true; 
}));


watch(() => userStore.isAdmin, (newValue) => {
  filteredItems.value = items.value.filter(item => {
    if (item.requiresAdmin && !newValue) {
      return false;
    }
    return true;
  });
});
</script>

<style lang="scss">
.va-sidebar {
  &__menu {
    padding: 2rem 0;
  }

  &-item {
    &__icon {
      width: 1.5rem;
      height: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .va-sidebar__item {
    background-color: #1C1C1C !important;
  }
  .va-sidebar__item.va-sidebar-item.va-sidebar-item--active.va-dropdown {
    border-color: white !important;
  }
  .router-link-active.router-link-exact-active.va-sidebar__item.va-sidebar-item.va-dropdown {
    border-color: white !important;
  }
  .va-icon.material-icons.va-sidebar-item__icon{
    color: white !important;

  }
}
</style>