<template>
  <RouterView />
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { useUserStore } from '@/stores/user-store'

const userStore = useUserStore()

onMounted(() => {
  userStore.fetchUserData()
})
</script>

<style lang="scss">
@import 'scss/main.scss';


#app {
  font-family: 'Inter', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #931271;
}

body {
  margin: 0;
  min-width: 20rem;
}
.va-button__content {
    color: #ffffff !important;
}

h3 {
  color: black;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
}
</style>
