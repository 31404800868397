<template>
  <div class="auth-layout grid grid-cols-12 content-center">
    <div class="flex col-span-12 p-4 justify-center">
      <div class="py-5 justify-center flex" to="/">
        <EasyLogo height="155" />
      </div>
    </div>

    <div class="flex justify-center col-span-12 p-4">
      <VaCard class="auth-layout__card">
        <VaCardContent class="bg-white">
          <VaTabs v-model="tabIndex" color="#000000" center>
            <template #tabs>
              <VaTab color="#a31433" name="login">{{ t('layouts.auth.card.content.login') }}</VaTab>
              <VaTab color="#a31433" name="signup">{{ t('layouts.auth.card.content.signUp') }}</VaTab>
            </template>
          </VaTabs>

          <VaSeparator />

          <div class="p-2">
            <RouterView />
          </div>
        </VaCardContent>
      </VaCard>
    </div>
  </div>
</template>

<script>
import EasyLogo from '@/components/EasyLogo.vue'
import { useI18n } from 'vue-i18n'

export default {
  name: 'AuthLayout',
  components: { EasyLogo },
  setup() {
    const { t } = useI18n()
    return { t }
  },
  data() {
    return {
      selectedTabIndex: 0,
    }
  },
  computed: {
    tabIndex: {
      set(tabName) {
        this.$router.push({ name: tabName })
      },
      get() {
        return this.$route.name
      },
    },
  },
}
</script>

<style lang="scss">
.auth-layout {
  min-height: 70vh;
  background-image: linear-gradient(to right, var(--va-background-primary), var(--va-white));

  &__card {
    width: 100%;
    max-width: 500px;
    max-height: 350px;
  }
  .va-input-wrapper__field input,
  .va-input-wrapper__field textarea {
    color: #000000 !important;
  }
}
</style>
