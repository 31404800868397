import { createApp } from 'vue';
import i18n from './i18n';
import { createVuestic } from 'vuestic-ui';
import { createGtm } from '@gtm-support/vue-gtm';
import { createPinia } from 'pinia';
import stores from './stores';
import router from './router';
import vuesticGlobalConfig from './services/vuestic-ui/global-config';
import App from './App.vue';
import "leaflet/dist/leaflet.css";


import L from 'leaflet';
delete (L.Icon.Default.prototype as any)._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: new URL('leaflet/dist/images/marker-icon-2x.png', import.meta.url).toString(),
  iconUrl: new URL('leaflet/dist/images/marker-icon.png', import.meta.url).toString(),
  shadowUrl: new URL('leaflet/dist/images/marker-shadow.png', import.meta.url).toString(),
});

const app = createApp(App);
const pinia = createPinia();

app.use(stores);
app.use(router);
app.use(i18n);
app.use(createVuestic({ config: vuesticGlobalConfig }));

if (import.meta.env.VITE_APP_GTM_ENABLED) {
  app.use(
    createGtm({
      id: import.meta.env.VITE_APP_GTM_KEY,
      debug: false,
      vueRouter: router,
    }),
  );
}

app.use(pinia);
app.mount('#app');