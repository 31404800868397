<template>
  <VaAccordion v-model="accordionValue" class="sidebar-accordion va-sidebar__menu__inner" multiple>
    <VaCollapse v-for="(route, idx) in items" :key="idx">
      <template #header>
        <VaSidebarItem
          style="color: white"
          :class="{ 'active-sidebar-item': isRouteActive(route) }"
          :active="isRouteActive(route)"
          :to="route.children ? undefined : { name: route.name }"
        >
          <VaSidebarItemContent>
            <VaIcon :name="route.meta.icon" class="va-sidebar-item__icon" />

            <VaSidebarItemTitle>
              {{ t(route.displayName) }}
            </VaSidebarItemTitle>
            <VaIcon v-if="route.children" />
          </VaSidebarItemContent>
        </VaSidebarItem>
      </template>
      <template v-for="(child, index) in route.children" :key="index">
        <VaSidebarItem
          :class="{ 'active-sidebar-item': isRouteActive(child) }"
          :active="isRouteActive(child)"
          :to="{ name: child.name }"
        >
          <VaSidebarItemContent>
            <div class="va-sidebar-item__icon" />

            <VaSidebarItemTitle>
              {{ t(child.displayName) }}
            </VaSidebarItemTitle>
          </VaSidebarItemContent>
        </VaSidebarItem>
      </template>
    </VaCollapse>
  </VaAccordion>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { INavigationRoute } from '../NavigationRoutes'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const props = withDefaults(
  defineProps<{
    items?: INavigationRoute[]
  }>(),
  {
    items: () => [],
  },
)

const accordionValue = ref<boolean[]>([])

onMounted(() => {
  accordionValue.value = props.items.map((item) => isItemExpanded(item))
})

function isRouteActive(item: INavigationRoute) {
  return item.name === useRoute().name
}

function isItemExpanded(item: INavigationRoute): boolean {
  if (!item.children) {
    return false
  }

  const isCurrentItemActive = isRouteActive(item)
  const isChildActive = !!item.children.find((child) => (child.children ? isItemExpanded(child) : isRouteActive(child)))

  return isCurrentItemActive || isChildActive
}
</script>

<style>
.active-sidebar-item {
  color: white !important;
  background-color: #1C1C1C !important;
  border-color: white !important;
}

.va-sidebar-item:hover {
  background-color: #1C1C1C !important;
  color: white !important;
  border-color: white !important;
}
.router-link-active{
background-color: #1C1C1C !important;
border-color: #ffffff !important;
}
.va-sidebar__item.va-sidebar-item{
background-color: #1C1C1C;
}
.va-sidebar__title.va-sidebar-item-title {
color: white;
}
</style>
