import { defineStore } from 'pinia';
import axios from '@/services/api/api';

export const useUserStore = defineStore('user', {
  state: () => ({
    id: null,
    name: '',
    username: '',
    email: '',
    isAdmin: false,
  }),
  actions: {

    async fetchUserData() {
      try {
        const response = await axios.get('/users/me');
        this.id = response.data.id;
        this.name = response.data.name;
        this.username = response.data.username;
        this.email = response.data.email;
    
        this.isAdmin = response.data.admin === "admin";
      } catch (error) {
        console.error('Failed to fetch user data', error);
      }
    },

    async changeUserName(newName) {
      if (this.id === null) {
        console.error('User ID is not set');
        return;
      }

      try {
        await axios.patch(`/users/${this.id}`, { user: { name: newName } });
        this.name = newName;
      } catch (error) {
        console.error('Failed to change user name', error);
      }
    },

    async changeUsername(newUsername) {
      if (this.id === null) {
        console.error('User ID is not set');
        return;
      }

      try {
        await axios.patch(`/users/${this.id}`, { user: { username: newUsername } });
        this.username = newUsername;
      } catch (error) {
        console.error('Failed to change username', error);
      }
    },

    async changePassword(oldPassword: string, newPassword: string) {
      if (this.id === null) {
        console.error('User ID is not set');
        return;
      }

      try {
        await axios.post(`/users/${this.id}/change_password`, {
          user: {
            old_password: oldPassword,
            new_password: newPassword,
          },
        });
      } catch (error) {
        throw error;
      }
    },

    async checkIfAdmin() {
      try {
        if (this.isAdmin) {
          return true;
        }
        const response = await axios.get('/users/me');
        this.isAdmin = response.data.admin === 1;
        return this.isAdmin;
      } catch (error) {
        console.error('Failed to check admin status', error);
        return false;
      }
    },
  },
});