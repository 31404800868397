<template>
  <VaNavbar
    class="app-layout-navbar"
    style="background-color: #000000; color: rgb(255, 255, 255); fill: rgb(255, 255, 255)"
  >
    <div class="left">
      <VaIconMenuCollapsed
        :class="{ 'x-flip': !isSidebarMinimized }"
        class="va-navbar__item"
        color="white"
        @click="toggleSidebar"
      />
      <img src="/logo.png" alt="Logo easy" width="90" />
    </div>
    <AppNavbarActions class="app-navbar__actions" />
    <img class="logo-over-triangle" src="/EasyCRM.png" alt="Logo sobre o triângulo" />
  </VaNavbar>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import VaIconMenuCollapsed from '../icons/VaIconMenuCollapsed.vue'
import AppNavbarActions from './components/AppNavbarActions.vue'

const store = useStore()

const isSidebarMinimized = computed(() => store.state.isSidebarMinimized)

const toggleSidebar = () => {
  store.commit('toggleSidebar')
  isSidebarMinimized.value = store.state.isSidebarMinimized
}
</script>

<style lang="scss" scoped>
.va-navbar {
  position: relative;
  box-shadow: var(--va-box-shadow);
  z-index: 2;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #000000;
  overflow: hidden;

  .va-icon-menu-collapsed {
    width: 20px;
    height: 20px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 400px solid transparent; 
    border-right: 400px solid transparent; 
    border-top: 300px solid #1C1C1C; 
  }
}

.left {
  display: flex;
  align-items: center;

  & > * {
    margin-right: 0.5rem;
  }

  & > *:last-child {
    margin-right: 0;
  }
}

.x-flip {
  transform: scaleX(-100%);
}

.logo-over-triangle {
  position: absolute;
  top: -25px; 
  left: 50%;
  transform: translateX(-50%);
  height: 40px; 
  z-index: 10; 
  width: 100px;
  height: 100px;
}

.app-navbar-center {
  display: flex;
  align-items: center;
  height: 1rem;

  @media screen and (max-width: 950px) {
    &__text {
      display: none;
    }
  }
}
</style>