{
  "layouts": {
    "auth": {
      "card": {
        "content": {
          "login": "Login",
          "signUp": "Cadastrar-se"
        }
      }
    }
  },
  "pages": {
    "auth": {
      "signUp": {
        "form": {
          "input": {
            "name": {
              "label": "Nome",
              "placeholder": "Digite seu nome"
            },
            "username": {
              "label": "Usuário",
              "placeholder": "Digite seu nome de usuário"
            },
            "email": {
              "label": "Email",
              "placeholder": "Digite seu email"
            },
            "password": {
              "label": "Senha",
              "placeholder": "Digite sua senha"
            },
            "passwordConfirmation": {
              "label": "Confirme de senha",
              "placeholder": "Digite sua senha novamente"
            }
          },
          "button": {
            "confirm": "Cadastrar"
          }
        }
      },
      "login": {
        "form": {
          "input": {
            "login": {
              "label": "Email ou usuário",
              "placeholder": "Digite seu email ou usuário"
            },
            "password": {
              "label": "Senha",
              "placeholder": "Digite sua senha"
            }
          },
          "button": {
            "confirm": "Entrar"
          }
        }
      }
    },
    "sortType": {
      "form": {
        "addSortType": {
          "title": "Adicionar novo tipo de ordenação",
          "input": {
            "name": {
              "label": "Tipo de ordenação",
              "placeholder": "Digite o nome do tipo de ordenação"
            }
          },
          "button": {
            "confirm": "Adicionar"
          }
        },
        "filter": {
          "input": {
            "perPage": {
              "label": "Tipos de ordenação por página",
              "placeholder": "Número de tipos de ordenação por página"
            },
            "name": {
              "label": "Filtrar por nome",
              "placeholder": "Nome do tipo de ordenação"
            }
          }
        }
      },
      "table": {
        "header": {
          "id": "ID",
          "name": "Nome",
          "actions": "Ações"
        }
      }
    },
    "brand": {
      "form": {
        "addBrand": {
          "title": "Adicionar nova marca",
          "input": {
            "name": {
              "label": "Marca",
              "placeholder": "Digite o nome da marca"
            }
          },
          "button": {
            "confirm": "Adicionar"
          }
        },
        "filter": {
          "input": {
            "perPage": {
              "label": "Marcas por página",
              "placeholder": "Número de marcas por página"
            },
            "name": {
              "label": "Filtrar por nome",
              "placeholder": "Nome da marca"
            }
          }
        }
      },
      "table": {
        "header": {
          "id": "ID",
          "name": "Nome",
          "actions": "Ações"
        }
      }
    },
    "company": {
      "form": {
        "addCompany": {
          "title": "Adicionar nova empresa",
          "input": {
            "name": {
              "label": "Nome da Empresa",
              "placeholder": "Digite o nome da empresa"
            },
            "cnpj": {
              "label": "CNPJ",
              "placeholder": "Digite o CNPJ da empresa"
            },
            "app_version": {
              "label": "Versão do App",
              "placeholder": "Digite a versão do app"
            },
            "data_integration_type": {
              "label": "Tipo de integração de dados",
              "placeholder": "Digite o tipo de integração de dados"
            },
            "brand_name": {
              "label": "Marca",
              "placeholder": "Selecione a marca"
            },
            "brand_id": {
              "label": "ID da Marca",
              "placeholder": "Digite o ID da marca"
            }
          },
          "button": {
            "confirm": "Adicionar"
          }
        },
        "filter": {
          "input": {
            "perPage": {
              "label": "Empresas por página",
              "placeholder": "Número de empresas por página"
            },
            "name": {
              "label": "Filtrar por nome",
              "placeholder": "Nome da empresa"
            }
          }
        }
      },
      "table": {
        "header": {
          "id": "ID",
          "name": "Nome",
          "cnpj": "CNPJ",
          "app_version": "Versão do App",
          "data_integration_type": "Tipo de integração de dados",
          "brand_name": "Nome da Marca",
          "actions": "Ações"
        }
      }
    },
    "module": {
      "form": {
        "addModule": {
          "title": "Adicionar novo módulo",
          "input": {
            "name": {
              "label": "Módulo",
              "placeholder": "Digite o nome do módulo"
            }
          },
          "button": {
            "confirm": "Adicionar"
          }
        },
        "filter": {
          "input": {
            "perPage": {
              "label": "Módulos por página",
              "placeholder": "Número de módulos por página"
            },
            "name": {
              "label": "Filtrar por nome",
              "placeholder": "Nome do módulo"
            }
          }
        }
      },
      "table": {
        "header": {
          "id": "ID",
          "name": "Nome",
          "actions": "Ações"
        }
      }
    },
    "clientModule": {
      "title": "Módulos da Empresa",
      "form": {
        "addClientModule": {
          "title": "Adicionar novo módulo à empresa",
          "input": {
            "module_multi_search": {
              "label": "Módulos",
              "placeholder": "Selecione os módulos"
            }
          },
          "button": {
            "confirm": "Adicionar"
          }
        },
        "filter": {
          "input": {
            "perPage": {
              "label": "Módulos por página",
              "placeholder": "Número de módulos por página"
            },
            "name": {
              "label": "Filtrar por nome",
              "placeholder": "Nome do módulo"
            }
          }
        }
      },
      "table": {
        "header": {
          "easyModuleId": "ID",
          "easyModuleName": "Nome",
          "actions": "Ações"
        }
      }
    }
  },
  "language": {
    "brazilian_portuguese": "Português",
    "english": "Inglês",
    "spanish": "Espanhol",
    "simplified_chinese": "Chinês Simplificado",
    "persian": "Persa"
  },
  "menu": {
    "auth": "Autenticação",
    "brands": "Marcas",
    "companies": "Empresas",
    "modules": "Módulos",
    "sort_types": "Tipos de Ordenação",
    "palletization_types": "Tipos de Palletização",
    "buttons": "Botões",
    "timelines": "Linhas do Tempo",
    "dashboard": "Painel de Controle",
    "settings": "Configurações",
    "billing": "Cobrança",
    "login": "Entrar",
    "signUp": "Cadastrar-se",
    "preferences": "Preferências",
    "payments": "Pagamentos",
    "pricing-plans": "Planos de Preços",
    "login-singup": "Entrar/Cadastrar",
    "404": "Páginas 404",
    "faq": "FAQ"
  },
  "user": {
    "language": "Mudar Idioma",
    "logout": "Sair",
    "profile": "Meu Perfil",
    "settings": "Configurações",
    "billing": "Faturamento",
    "faq": "FAQ",
    "helpAndSupport": "Ajuda & Suporte",
    "projects": "Projetos",
    "account": "Conta",
    "explore": "Explorar"
  },
  "treeView": {
    "basic": "Básico",
    "icons": "I:cones",
    "selectable": "Selecionável",
    "editable": "Editável",
    "advanced": "Advançado"
  },
  "chat": {
    "title": "Gráficos"
  },
  "helpAndSupport": "Ajuda & Suporte",
  "aboutVuesticAdmin": "Sobre Vuestic Admin",
  "search": {
    "placeholder": "Buscar..."
  },
  "users": {
    "id": "id",
    "users": "Usuários",
    "name": "Nome",
    "login": "Login"
  },
  "companies": {
    "id": "id",
    "companies": "Empresas"
  },
  "contacts": {
    "id": "id",
    "contacts": "Contatos"
  },
  "localities": {
    "id": "id",
    "localities": "Centros de Distribuição",
    "registrations": "Mapa",
    "map": "Mapa",
    "register": "Registro",
    "easy": "Easy Pelo Mundo"
  },
  "locality_types": {
    "id": "id",
    "locality_types": "Tipos de centros de distribuição",
    "locality_types_CDs": "Tipos de CDs"
  },
  "palletization_types": {
    "id": "id",
    "name": "Nome",
    "palletization_types": "Tipos de centros de palletização"
  },
  "vuestic": {
    "select": "select",
    "pagination": "pagination",
    "toggleDropdown": "toggleDropdown",
    "goToSpecificPage": "goToSpecificPage",
    "optionsFilter": "optionsFilter",
    "search": "search"
  },
  "errors": {
    "messages": {
      "blank": "não pode ficar em branco",
      "invalid": "é inválido",
      "not_a_number": "não é um número"
    },
    "full_messages": {
      "name": {
        "blank": "Nome não pode ficar em branco",
        "invalid": "Nome é inválido"
      },
      "email": {
        "blank": "Email não pode ficar em branco",
        "invalid": "Email é inválido"
      },
      "phone": {
        "blank": "Telefone não pode ficar em branco",
        "not_a_number": "Telefone não é um número"
      },
      "job_title": {
        "blank": "Cargo não pode ficar em branco"
      }
    }
  }
}